<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-file-document-plus-outline
      </v-icon> Change Orders
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showCreateDialog = true"
        >
          Add New
        </v-btn>
      </v-col>
      <hr>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="budgetChangeOrders"
        :search="search"
        disable-sort
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on,attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ item.name }}</span>
                </template>
                <span v-if="item.description !== null">Description: <br> {{ item.description }}</span>
                <span v-else>Description: <br> N/A</span>
              </v-tooltip>
            </td>
            <td>{{ item.quantity }} <span class="hrs-style">Hrs</span></td>
            <td><span class="currency-style">{{ budgetDetails.project.currency }}</span> {{ item.unit_cost }}</td>
            <td><span class="currency-style">{{ budgetDetails.project.currency }}</span> {{ item.amount }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                :disabled="item.database === false"
                icon
                color="#C62828"
                @click.stop
                @click="deleteChangeOrder(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <create-change-order
      v-if="showCreateDialog"
      @closed="showCreateDialog = false"
      @success="getLatestChangeOrder"
    />
    <delete-change-order
      v-if="showDelete"
      :title="'Delete this change order ?'"
      :message="'Action will permanently remove the item from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed"
    />
  </v-container>
</template>

<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import CreateChangeOrder from './CreateChangeOrder.vue';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import Constants from 'src/constants';

  export default {
    name: 'BudgetChangeOrders',
    components: {
      'centre-spinner': spinner,
      'create-change-order': CreateChangeOrder,
      'delete-change-order': DeleteDialog,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY');
      },
    },
    data () {
      return {
        search: '',
        loading: false,
        headers: [
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Quantity', value: 'quantity' },
          { text: 'Unit Cost', value: 'unit_cost' },
          { text: 'Amount', value: 'amount' },
          { text: 'Added On', value: 'created_at' },
          { text: 'Delete', value: 'delete' },
        ],
        showCreateDialog: false,
        showDelete: false,
        changeOrderId: '',
      };
    },
    computed: {
      budgetChangeOrders () {
        const list = this.$store.getters['projects/getBudgetChangeOrders'];
        const sortedList = list.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
      },
      budgetDetails () {
        return this.$store.getters['projects/getBudgetDetails'];
      },
    },
    async mounted () {
        this.loading = true;
        await this.getBudgetChangeOrders();
        this.loading = false;
    },
    methods: {
      async getBudgetChangeOrders () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchBudgetChangeOrders', {
          budgetId: this.$route.params.budgetId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      deleteChangeOrder (id) {
        this.changeOrderId = id;
        this.showDelete = true;
      },
      async deleteConfirmed () {
        this.loading = true;
        await this.$store.dispatch('projects/deleteChangeOrder', {
          changeOrderId: this.changeOrderId,
          budgetId: this.$route.params.budgetId,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Budget change order deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('change-order-done');
          this.showDelete = false;
          this.loading = false;
          this.getBudgetChangeOrders();
        }).catch(() => {
          this.loading = false;
        });
      },
      getLatestChangeOrder () {
        this.getBudgetChangeOrders();
        this.$emit('change-order-done');
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}
.v-data-table {
  background-color: #ECEFF1
}
.status-style {
  font-weight: bold;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
}
.hrs-style {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-size: 11px;
  text-transform: capitalize;
}
</style>
