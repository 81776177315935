<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-menu
      v-if="isMobile"
      offset-x
      right
    >
      <template #activator="{ on, attrs }">
        <v-icon
          dark
          large
          color="#37474F"
          v-bind="attrs"
          v-on="on"
        >
          mdi-menu
        </v-icon>
      </template>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getBudgetCheckInRecords()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-multiple-check-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Check-Ins
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getBudgetChangeOrders()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-document-plus-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Change Orders
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
      color="red darken-3"
      class="ml-7 my-back-btn-style"
      text
      x-large
      dark
      @click="back()"
    >
      <v-icon
        left
        dark
      >
        mdi-arrow-left-bold
      </v-icon>
      Back
    </v-btn>
    <div v-if="showBudgetDetails">
      <p class="project-header">
        Project Name:- <font
          class="project-name"
        >
          {{ budgetDetails.project.name }}
        </font>
      </p>
      <v-row
        class="cost-items"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="budget-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="budget-content">
              Budget Name:- <font
                class="font-my-style"
              >
                {{ budgetDetails.name }}
              </font>
            </p>
            <p class="budget-content">
              High Risk Work:- <font
                :color="getHrwColor(budgetDetails.high_risk_work)"
              >
                {{ budgetDetails.high_risk_work | displayHighRisk }}
              </font>
            </p>
            <p class="budget-content">
              Projected Work Hours:- <font
                class="font-my-style"
              >
                {{ budgetDetails.quantity }} Hrs
              </font>
            </p>
            <p class="budget-content">
              Status:- <font
                v-if="budgetDetails.status !== null"
                :color="getStatusColor(budgetDetails.status)"
              >
                {{ budgetDetails.status | formatStatusVal }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="budget-date-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="budget-content">
              Planned Start Date:- <font
                v-if="budgetDetails.planned_start_date !== null"
                class="font-my-style"
              >
                {{ budgetDetails.planned_start_date | convertToLocal }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="budget-content">
              Planned End Date:- <font
                v-if="budgetDetails.planned_end_date !== null"
                class="font-my-style"
              >
                {{ budgetDetails.planned_end_date | convertToLocal }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="budget-content">
              Actual Start Date:- <font
                v-if="budgetDetails.actual_start_date !== null"
                class="font-my-style"
              >
                {{ budgetDetails.actual_start_date | convertToLocal }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
            <p class="budget-content">
              Actual End Date:- <font
                v-if="budgetDetails.actual_end_date !== null"
                class="font-my-style"
              >
                {{ budgetDetails.actual_end_date | convertToLocal }}
              </font>
              <font
                v-else
                class="font-my-style"
              >
                N/A
              </font>
            </p>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            class="budget-cost-card"
            color="ECEFF1"
            shaped
            hover
          >
            <v-card-title />
            <p class="budget-content">
              Projected Budget:- <font
                class="font-my-style"
              >
                <span class="currency-style">{{ budgetDetails.project.currency }}</span> {{ budgetDetails.projected_budget }}
              </font>
            </p>
            <p class="budget-content">
              Projected Cost:- <font
                class="font-my-style"
              >
                <span class="currency-style">{{ budgetDetails.project.currency }}</span> {{ budgetDetails.projected_cost }}
              </font>
            </p>
            <p class="budget-content">
              Variance Total: <font
                :color="getDifferenceColor(budgetDetails.variance_total)"
                class="difference-font"
              >
                <span class="currency-style">{{ budgetDetails.project.currency }}</span> {{ budgetDetails.variance_total }}
              </font>
            </p>
            <p class="budget-content">
              Timesheet Hours:- <font class="font-my-style">
                <font v-if="budgetDetails.time_sheet_hours !== null">
                  {{ budgetDetails.time_sheet_hours }} Hrs
                </font>
                <font v-else>
                  N/A
                </font>
              </font>
            </p>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <br>
    <hr>
    <br>
    <v-navigation-drawer
      v-if="!isMobile"
      permanent
      color="#ECEFF1"
      expand-on-hover
      absolute
      bottom
      floating
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="nav-title-style">
              <v-list-item-icon>
                <v-icon
                  class="drawer-style"
                >
                  mdi-checkbox-multiple-blank-outline
                </v-icon>
              </v-list-item-icon>
              Budget Details
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <br>
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="selectedItem"
          color="#37474F"
          mandatory
        >
          <v-list-item
            link
            @click="getBudgetCheckInRecords()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-account-multiple-check-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Check-Ins
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            @click="getBudgetChangeOrders()"
          >
            <v-list-item-icon>
              <v-icon class="drawer-style">
                mdi-file-document-plus-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title class="drawer-item-style">
              Change Orders
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <centre-spinner
      :loading="loading"
    />
    <budget-check-in-records v-if="showBudgetCheckInRecords" />
    <budget-change-orders
      v-if="showBudgetChangeOrders"
      @change-order-done="getBudgetDetails"
    />
  </v-container>
</template>

<script>
  import BudgetCheckInRecords from './BudgetCheckInRecords.vue';
  import BudgetChangeOrders from './BudgetChangeOrders.vue';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import Constants from 'src/constants';

  export default {
    name: 'BudgetSecondNavDrawer',
    components: {
      'budget-check-in-records': BudgetCheckInRecords,
      'budget-change-orders': BudgetChangeOrders,
      'centre-spinner': spinner,
    },
    filters: {
      displayHighRisk (status) {
        if (status === true) {
          return 'Yes*';
        } else {
          return 'No';
        }
      },
      formatStatusVal (val) {
        if (val === 'active') {
          return 'Active*';
        } else if (val === 'pending') {
          return 'Pending';
        } else if (val === 'completed') {
          return 'Completed*';
        } else {
          return val;
        }
      },
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY');
      },
    },
    data: () => ({
      selectedItem: -1,
      showBudgetCheckInRecords: true,
      showBudgetChangeOrders: false,
      loading: false,
    }),
    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
      budgetDetails () {
        return this.$store.getters['projects/getBudgetDetails'];
      },
      showBudgetDetails () {
        if (Object.keys(this.budgetDetails).length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
      this.selectedItem = 0;
      await this.getBudgetDetails();
    },
    methods: {
      back () {
        this.$store.dispatch('projects/storeProjectSecondNavDrawerTab', {
          toTab: 3,
        });
        const projectId = this.$route.params.projectId;
        this.$router.push(`/projects/${projectId}/details`);
      },
      async getBudgetDetails () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchBudgetDetails', {
          budgetId: this.$route.params.budgetId,
        });
        this.loading = false;
      },
      getDifferenceColor (val) {
        if (val === 0) {
          return '#2E7D32';
        }
        return '#C62828';
      },
      getHrwColor (value) {
        if (value === true) return '#C62828';
        else { return '#2E7D32'; }
      },
      getStatusColor (status) {
        if (status === 'completed' || status === 'active') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      getBudgetCheckInRecords () {
        this.showBudgetChangeOrders = false;
        this.showBudgetCheckInRecords = true;
      },
      getBudgetChangeOrders () {
        if (this.budgetDetails.database === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Change orders are only allowed in budgets made with our software.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.showBudgetCheckInRecords = false;
        this.showBudgetChangeOrders = true;
      },
    },
  };
</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.my-back-btn-style {
  margin-bottom: -20px;
}
@media (max-width: 959px) {
  .my-back-btn-style {
    margin-bottom: 0px;
    margin-left: 0px !important;
  }
  .budget-card {
    margin-left: -20px;
  }
  .budget-date-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .budget-cost-card {
    margin-top: -20px;
    margin-left: -20px;
  }
  .project-header {
    margin-left: 20px;
  }
}
@media (min-width: 959px) and (max-width: 1245px) {
  .font-my-style {
  font-weight: bold;
  font-size: 12px !important;
  color: #37474F;
  }
  .budget-content {
  font-size: 12px !important;
  font-weight: bold;
  color: #546E7A;
  margin-left: 12px !important;
  line-height: 0.8;
  }
}
.drawer-style {
  font-size:25px;
  color:#37474F;
  font-weight:bold;
}
.drawer-item-style {
  font-size:14px !important;
  color:#37474F !important;
  font-weight:bold !important;
}
.type-style {
  text-transform: capitalize;
  font-weight: bold;
}
.my-style {
  margin-bottom: 10px !important;
}
.my-hrw-style {
  text-transform: capitalize;
  font-weight: bold;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.status-font-style {
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  font-weight: bold;
}
.job-status-style {
  text-transform: capitalize;
  font-weight: bold;
}
.budget-content {
  font-size: 14px;
  font-weight: bold;
  color: #546E7A;
  margin-left: 15px;
  line-height: 0.8;
}
.font-my-style {
  font-weight: bold;
  font-size: 14px;
  color: #37474F;
}
.difference-font {
  font-weight: bold;
  font-size: 14px;
}
.budget-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.budget-cost-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.budget-date-card {
  background: #ECEFF1;
  border-bottom: 2px solid #CFD8DC !important;
  border-top: 8px solid #CFD8DC !important;
  padding-top: 15px;
  cursor: default;
}
.project-header {
  margin-left: 40px;
  margin-top: 25px;
  margin-bottom: -20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  color: #37474F;
  font-size: 18px;
}
.project-name {
  color: #FF3700;
}
.cost-items {
  margin-left: 25px;
}
.currency-style {
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
  font-size: 12px;
}
</style>
